import styled from "styled-components";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {ButtonUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {useWindowSize} from "@tools/hooks";
import { AdaptiveLinkCt } from "@components";

export const SimpleTelegramSection = () => {

    const { width } = useWindowSize();

    return (
        <SimpleTelegramSectionContainer>
            <SimpleTelegramSectionTitleContainer>
                <SimpleTelegramSectionTitle swichPoint={LAPTOP_MOBILE_POINTER}>Удобно для клиента, просто для вас</SimpleTelegramSectionTitle>
            </SimpleTelegramSectionTitleContainer>

            <SimpleTelegramSectionItems>

                <SimpleTelegramSectionItem style={{'width': width && width > LAPTOP_MOBILE_POINTER ? '391px' : '100%', 'height': width && width > LAPTOP_MOBILE_POINTER ? '438px' : '100%', 'background': 'var(--background-second)'}}>
                    <SimpleTelegramSectionItemInfo>
                        <TypographyUI.H3 className="title">Выше конверсии</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">Клиенту не нужно вручную вводить ваши реквизиты — достаточно нажать кнопку и сразу оплатить товар или услугу</TypographyUI.Body1Regular>
                    </SimpleTelegramSectionItemInfo>
                    <SimpleTelegramSectionItemImage style={{'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative', 'bottom': '20px', 'right': width && width > LAPTOP_MOBILE_POINTER ? '75px' : '0', 'width': width && width > LAPTOP_MOBILE_POINTER ? '269px' : '100%'}}>
                        <StaticImage
                            src="../../../images/telegram/SimpleTelegramSectionImage1.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </SimpleTelegramSectionItemImage>
                </SimpleTelegramSectionItem>

                <SimpleTelegramSectionItem style={{'width': width && width > LAPTOP_MOBILE_POINTER ? '729px' : '100%', 'height': width && width > LAPTOP_MOBILE_POINTER ? '438px' : '100%', 'background': 'var(--background-main-grey)'}}>
                    <SimpleTelegramSectionItemInfo style={{'maxWidth': width && width > LAPTOP_MOBILE_POINTER ? '100%' : '100%'}}>
                        <TypographyUI.H3 className="title">Удобная оплата</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">Покупатели могут оплачивать товары и услуги по карте или через СБП</TypographyUI.Body1Regular>
                    </SimpleTelegramSectionItemInfo>
                    <SimpleTelegramSectionItemImage style={{'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative', 'bottom': '0x', 'right': width && width > LAPTOP_MOBILE_POINTER ? '100px' : '0', 'width': width && width > LAPTOP_MOBILE_POINTER ? '542px' : '100%'}}>
                        <StaticImage
                            src="../../../images/telegram/SimpleTelegramSectionImage2.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </SimpleTelegramSectionItemImage>
                </SimpleTelegramSectionItem>


                <SimpleTelegramSectionItem style={{'width': width && width > LAPTOP_MOBILE_POINTER ? '729px' : '100%', 'height': width && width > LAPTOP_MOBILE_POINTER ? '438px' : '100%', 'background': 'var(--background-main-grey)'}}>
                    <SimpleTelegramSectionItemInfo style={{'maxWidth': width && width > LAPTOP_MOBILE_POINTER ? '80%' : '100%'}}>
                        <TypographyUI.H3 className="title">Работа без сайта</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">Вы можете полностью автоматизировать прием платежей — ваш Телеграм-чат будет работать как полноценный онлайн-магазин</TypographyUI.Body1Regular>
                    </SimpleTelegramSectionItemInfo>
                    <SimpleTelegramSectionItemImage style={{'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative', 'bottom': '15px', 'right': width && width > LAPTOP_MOBILE_POINTER ? '65px' : '0', 'width': width && width > LAPTOP_MOBILE_POINTER ? '578px' : '100%'}}>
                        <StaticImage
                            src="../../../images/telegram/SimpleTelegramSectionImage3.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </SimpleTelegramSectionItemImage>
                </SimpleTelegramSectionItem>

                <SimpleTelegramSectionItem style={{'width': width && width > LAPTOP_MOBILE_POINTER ? '391px' : '100%', 'height': width && width > LAPTOP_MOBILE_POINTER ? '438px' : '100%', 'background': 'var(--background-second)'}}>
                    <SimpleTelegramSectionItemInfo>
                        <TypographyUI.H3 className="title">Экономия времени</TypographyUI.H3>
                        <TypographyUI.Body1Regular className="description">Сервис создаст платежную форму, отправит счет, примет деньги, выдаст чек и передаст информацию в «Мой налог» за вас</TypographyUI.Body1Regular>
                    </SimpleTelegramSectionItemInfo>
                    <SimpleTelegramSectionItemImage style={{'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative', 'bottom': '15px', 'right': width && width > LAPTOP_MOBILE_POINTER ? '65px' : '0', 'width': width && width > LAPTOP_MOBILE_POINTER ? '266px' : '100%'}}>
                        <StaticImage
                            src="../../../images/telegram/SimpleTelegramSectionImage4.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </SimpleTelegramSectionItemImage>
                </SimpleTelegramSectionItem>

                <SimpleTelegramSectionItem style={{'width': '100%', 'height': width && width > LAPTOP_MOBILE_POINTER ? '438px' : '100%', 'background': 'var(--background-main-grey)'}}>
                    <SimpleTelegramSectionItemInfo style={{'maxWidth': width && width > LAPTOP_MOBILE_POINTER ? '43%' : '100%'}}>
                        <TypographyUI.H3 className="title">Мы не берем комиссию за прием платежей, только 3,5% на вывод средств через СБП или на банковскую карту</TypographyUI.H3>

                        <AdaptiveLinkCt to="https://pro.selfwork.ru">
                            <SimpleTelegramSectionBtn>Настроить оплату</SimpleTelegramSectionBtn>
                        </AdaptiveLinkCt>

                    </SimpleTelegramSectionItemInfo>
                    <SimpleTelegramSectionItemImage style={{'display': width && width > LAPTOP_MOBILE_POINTER ? 'block': 'none', 'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative', 'bottom': width && width > LAPTOP_MOBILE_POINTER ? '25px' : '25px', 'right': width && width > LAPTOP_MOBILE_POINTER ? '25px' : '0', 'width': width && width > LAPTOP_MOBILE_POINTER ? '473px' : '100%'}}>
                        <StaticImage
                            src="../../../images/telegram/SimpleTelegramSectionImage5.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </SimpleTelegramSectionItemImage>
                </SimpleTelegramSectionItem>

            </SimpleTelegramSectionItems>

        </SimpleTelegramSectionContainer>
    )
};

const SimpleTelegramSectionContainer = styled.section`
    display: flex;
    margin-top: 160px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-direction: column;
    margin-bottom: 100px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 80px -19px 20px;
    }
`;

const SimpleTelegramSectionTitleContainer = styled.div`
    width: 100%;
    margin-bottom: 60px;
    @media (max-width: 609px) {
        padding: 0 20px;
    }
`;

const SimpleTelegramSectionTitle = styled(TypographyUI.H2)`
    margin-bottom: 24px;
`;

const SimpleTelegramSectionDescription = styled(TypographyUI.Body1Regular)`
    color: var(--text-secondary);
    width: 752px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;


const SimpleTelegramSectionItems = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
`;

const SimpleTelegramSectionItem = styled.div`
    padding-top: 40px;
    padding-left: 28px;
    padding-right: 28px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    @media (max-width: 609px) {
        border-radius: 0px;
        padding-top: 28px;
        padding-bottom: 20px;
    }
`;

const SimpleTelegramSectionItemInfo = styled.div`
    & .title {
        margin-bottom: 16px;
    }
    & .description {
        color: var(--text-secondary);
    }
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        padding-bottom: 16px;
    }
`;

const SimpleTelegramSectionItemImage = styled.div`
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 300px;
        margin: 0 auto;
        padding: 20px 0 0;
    }
    
`;

const SimpleTelegramSectionBtn = styled(ButtonUI)`
    width: 276px;
    margin-top: 40px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin-top: 20px;
        width: 100%;
    }
`;
