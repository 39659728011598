import styled from "styled-components";
import React, {useState} from "react";
import {StaticImage} from "gatsby-plugin-image";
import {ButtonUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";

import srcPlatformDocument from "@images/main/icon-platform-document.svg";
import srcHowReceiveMoneySectionCheck from "@images/telegram/HowReceiveMoneySectionCheck.svg";

export const HowReceiveMoneySection = () => {

    const [activeIndex, setActiveIndex] = useState<number>(0);

    return (
        <HowReceiveMoneySectionContainer id={'HowReceiveMoneySection'}>
            <HowReceiveMoneySectionTitle swichPoint={LAPTOP_MOBILE_POINTER}>
                Как легко получать деньги через Телеграм или социальные сети
            </HowReceiveMoneySectionTitle>
            <HowReceiveMoneySectionDescription >
                Используйте несколько инструментов приёма оплаты для разных целей
            </HowReceiveMoneySectionDescription>

            <HowReceiveMoneySectionComponent>
                <HowReceiveMoneySectionComponentTabItems>
                    <HowReceiveMoneySectionComponentTabItem className={activeIndex == 0 ? 'active' : ''}
                                                     onClick={() => setActiveIndex(0)}>Универсальная ссылка</HowReceiveMoneySectionComponentTabItem>
                    <HowReceiveMoneySectionComponentTabItem className={activeIndex == 1 ? 'active' : ''}
                                                     onClick={() => setActiveIndex(1)}>Уникальная ссылка</HowReceiveMoneySectionComponentTabItem>
                    <HowReceiveMoneySectionComponentTabItem className={activeIndex == 2 ? 'active' : ''}
                                                     onClick={() => setActiveIndex(2)}>Автоматическая оплата</HowReceiveMoneySectionComponentTabItem>
                </HowReceiveMoneySectionComponentTabItems>
                <HowReceiveMoneySectionComponentDetailItems>
                    <HowReceiveMoneySectionComponentDetailItem className={activeIndex == 0 ? 'active' : ''}>

                        <HowReceiveMoneySectionComponentDetailItemTitle>Вы создаете одну или несколько универсальных ссылок, в которых указана фиксированная сумма оплаты</HowReceiveMoneySectionComponentDetailItemTitle>

                        <HowReceiveMoneySectionComponentDetailItemContent>
                            <HowReceiveMoneySectionComponentDetailItemElements>
                                <HowReceiveMoneySectionComponentDetailItemElement>
                                    <div className='icon'><img src={srcHowReceiveMoneySectionCheck} alt=""/></div>
                                    <HowReceiveMoneySectionComponentDetailItemText>
                                        <TypographyUI.H4Bold>Кому подойдет</TypographyUI.H4Bold>
                                        <TypographyUI.Body1Regular className="text">Самозанятым, которые предлагают небольшой ассортимент товаров или услуг
                                        </TypographyUI.Body1Regular>
                                    </HowReceiveMoneySectionComponentDetailItemText>
                                </HowReceiveMoneySectionComponentDetailItemElement>
                                <HowReceiveMoneySectionComponentDetailItemElement>
                                    <div className='icon'><img src={srcHowReceiveMoneySectionCheck} alt=""/></div>
                                    <HowReceiveMoneySectionComponentDetailItemText>
                                        <TypographyUI.H4Bold>Преимущество</TypographyUI.H4Bold>
                                        <TypographyUI.Body1Regular className="text">Не нужно создавать отдельную ссылку для каждой продажи
                                        </TypographyUI.Body1Regular>
                                    </HowReceiveMoneySectionComponentDetailItemText>
                                </HowReceiveMoneySectionComponentDetailItemElement>
                            </HowReceiveMoneySectionComponentDetailItemElements>
                            <HowReceiveMoneySectionComponentDetailItemAdditional style={{'top': '-20px'}}>
                                <HowReceiveMoneySectionComponentDetailItemImage>
                                    <StaticImage
                                        src="../../../images/telegram/HowReceiveMoneySectionImage1.png"
                                        loading="eager"
                                        placeholder="none"
                                        formats={["auto", "webp"]}
                                        width={355}
                                        height={286}
                                        layout="fixed"
                                        objectFit="contain"
                                        alt=""
                                    />
                                </HowReceiveMoneySectionComponentDetailItemImage>
                            </HowReceiveMoneySectionComponentDetailItemAdditional>
                        </HowReceiveMoneySectionComponentDetailItemContent>

                    </HowReceiveMoneySectionComponentDetailItem>
                    <HowReceiveMoneySectionComponentDetailItem className={activeIndex == 1 ? 'active' : ''}>

                        <HowReceiveMoneySectionComponentDetailItemTitle>Каждый раз вы генерируете уникальную ссылку с разными суммами и назначениями платежа</HowReceiveMoneySectionComponentDetailItemTitle>

                        <HowReceiveMoneySectionComponentDetailItemContent>
                            <HowReceiveMoneySectionComponentDetailItemElements>
                                <HowReceiveMoneySectionComponentDetailItemElement>
                                    <div className='icon'><img src={srcHowReceiveMoneySectionCheck} alt=""/></div>
                                    <HowReceiveMoneySectionComponentDetailItemText>
                                        <TypographyUI.H4Bold>Кому подойдет</TypographyUI.H4Bold>
                                        <TypographyUI.Body1Regular className="text">Самозанятым с плавающим ценником, физлицам для простых переводов
                                        </TypographyUI.Body1Regular>
                                    </HowReceiveMoneySectionComponentDetailItemText>
                                </HowReceiveMoneySectionComponentDetailItemElement>
                                <HowReceiveMoneySectionComponentDetailItemElement>
                                    <div className='icon'><img src={srcHowReceiveMoneySectionCheck} alt=""/></div>
                                    <HowReceiveMoneySectionComponentDetailItemText>
                                        <TypographyUI.H4Bold>Преимущество</TypographyUI.H4Bold>
                                        <TypographyUI.Body1Regular className="text">Кто угодно может отправить вам деньги для разных целей
                                        </TypographyUI.Body1Regular>
                                    </HowReceiveMoneySectionComponentDetailItemText>
                                </HowReceiveMoneySectionComponentDetailItemElement>
                            </HowReceiveMoneySectionComponentDetailItemElements>
                            <HowReceiveMoneySectionComponentDetailItemAdditional style={{'top': '-45px'}}>
                                <HowReceiveMoneySectionComponentDetailItemImage>
                                    <StaticImage
                                        src="../../../images/telegram/HowReceiveMoneySectionImage2.png"
                                        loading="eager"
                                        placeholder="none"
                                        formats={["auto", "webp"]}
                                        width={355}
                                        height={273}
                                        layout="fixed"
                                        objectFit="contain"
                                        alt=""
                                    />
                                </HowReceiveMoneySectionComponentDetailItemImage>
                            </HowReceiveMoneySectionComponentDetailItemAdditional>
                        </HowReceiveMoneySectionComponentDetailItemContent>
                    </HowReceiveMoneySectionComponentDetailItem>
                    <HowReceiveMoneySectionComponentDetailItem
                        className={activeIndex == 2 ? 'active' : ''}>
                        <HowReceiveMoneySectionComponentDetailItemTitle>Вы можете настроить Телеграм-бот, который самостоятельно генерирует и отправляет платежную форму</HowReceiveMoneySectionComponentDetailItemTitle>

                        <HowReceiveMoneySectionComponentDetailItemContent>
                            <HowReceiveMoneySectionComponentDetailItemElements>
                                <HowReceiveMoneySectionComponentDetailItemElement>
                                    <div className='icon'><img src={srcHowReceiveMoneySectionCheck} alt=""/></div>
                                    <HowReceiveMoneySectionComponentDetailItemText>
                                        <TypographyUI.H4Bold>Кому подойдет</TypographyUI.H4Bold>
                                        <TypographyUI.Body1Regular className="text">Самозанятым, которые не успевают обрабатывать заявки клиентов и получают много заказов
                                        </TypographyUI.Body1Regular>
                                    </HowReceiveMoneySectionComponentDetailItemText>
                                </HowReceiveMoneySectionComponentDetailItemElement>
                                <HowReceiveMoneySectionComponentDetailItemElement>
                                    <div className='icon'><img src={srcHowReceiveMoneySectionCheck} alt=""/></div>
                                    <HowReceiveMoneySectionComponentDetailItemText>
                                        <TypographyUI.H4Bold>Преимущество</TypographyUI.H4Bold>
                                        <TypographyUI.Body1Regular className="text">Достаточно один раз настроить бот и больше не создавать ссылки вручную
                                        </TypographyUI.Body1Regular>
                                    </HowReceiveMoneySectionComponentDetailItemText>
                                </HowReceiveMoneySectionComponentDetailItemElement>
                            </HowReceiveMoneySectionComponentDetailItemElements>
                            <HowReceiveMoneySectionComponentDetailItemAdditional style={{'top': '-25px'}}>
                                <HowReceiveMoneySectionComponentDetailItemImage>
                                    <StaticImage
                                        src="../../../images/telegram/HowReceiveMoneySectionImage3.png"
                                        loading="eager"
                                        placeholder="none"
                                        formats={["auto", "webp"]}
                                        width={355}
                                        height={283}
                                        layout="fixed"
                                        objectFit="contain"
                                        alt=""
                                    />
                                </HowReceiveMoneySectionComponentDetailItemImage>
                            </HowReceiveMoneySectionComponentDetailItemAdditional>
                        </HowReceiveMoneySectionComponentDetailItemContent>
                    </HowReceiveMoneySectionComponentDetailItem>
                </HowReceiveMoneySectionComponentDetailItems>
            </HowReceiveMoneySectionComponent>

        </HowReceiveMoneySectionContainer>
    )
};

const HowReceiveMoneySectionContainer = styled.section`
    display: flex;
    margin-top: 160px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-direction: column;
    margin-bottom: 100px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 80px -19px 20px;
    }
`;

const HowReceiveMoneySectionTitle = styled(TypographyUI.H2)`
    width: 600px;
    margin: 0 auto 16px;
    text-align: center;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
        margin: 0 auto 16px;
        padding: 0 20px;
    }
`;

const HowReceiveMoneySectionDescription = styled(TypographyUI.Body1Regular)`
    width: 600px;
    margin: 0 auto 60px;
    text-align: center;
    color: var(--text-secondary);
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
        margin: 0 auto 40px;
        padding: 0 20px;
    }
`;

const HowReceiveMoneySectionComponent = styled.div`
    background: var(--background-main-grey);
    height: 466px;
    width: 100%;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        height: 100%;
        flex-direction: column;
        border-radius: 12px;
    }
    @media (max-width: 609px) {
        border-radius: 0;
    }
`;

const HowReceiveMoneySectionComponentTabItems  = styled.div`
    width: 292px;
    display: flex;
    align-items: flex-end;
    padding-top: 20px;
    height: 100%;
    flex-direction: column;
    padding-right: 40px;
    border-right: 1px solid var(--divider);
    padding-bottom: 30px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        align-items: normal;
        justify-content: space-around;
        height: 100%;
        width: 100%;
        flex-direction:row;
        padding-right: 10px;
        border-right: unset;
        border-bottom: 1px solid var(--divider);
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 10px;
    }
`;

const HowReceiveMoneySectionComponentTabItem = styled(TypographyUI.H3)`
    cursor: pointer;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: right;
    max-width: 215px;
    @keyframes fadeEffect {
        from {opacity: 0;}
        to {opacity: 1;}
    }
    &.active {
        color: var(--accent-main);
        &:after {
            content: "";
            position: absolute;
            border-right: 4px solid var(--accent-main);
            animation: fadeEffect 1s;
            height: 100px;
            padding-right: 38.5px;
            top: -10px;
        }
    }
    &:hover {
        color: var(--accent-main-hover);
    }
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin-bottom: 0px;
        margin-top: 0px;
        font-size: 17px;
        line-height: 24px;
        max-width: 100%;
        width: 33%;
        text-align: center;
        &.active {
            &:after {
                content: "";
                position: absolute;
                border-right: unset;
                padding-right: unset;
                border-bottom: 4px solid var(--accent-main);
                animation: 1s ease 0s 1 normal none running fadeEffect;
                top: 0;
                bottom: -20px;
                left: 0;
                width: 100%;
                height: auto;
            }
        }
    }
`;

const HowReceiveMoneySectionComponentDetailItems = styled.div`
    height: 100%;
    width: 852px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
        padding: 0 20px 27px;
    }
`;

const HowReceiveMoneySectionComponentDetailItem = styled.div`
    animation: fadeEffect 1s;
    display: none;
    height: 100%;
    gap: 30px;
    padding: 50px 30px 30px;
    flex-direction: column;
    &.active {
        display: flex;
    }
    @keyframes fadeEffect {
        from {opacity: 0;}
        to {opacity: 1;}
    }
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        flex-direction: column;
        padding: 20px;
        gap: 0px;
    }
`;

const HowReceiveMoneySectionComponentDetailItemContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;

const HowReceiveMoneySectionComponentDetailItemTitle = styled(TypographyUI.H3)`
    margin-bottom: 20px;
`;

const HowReceiveMoneySectionComponentDetailItemElements = styled.div`
    display: flex;
    flex-direction: column;
    gap: 44px;
    width: 385px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;
const HowReceiveMoneySectionComponentDetailItemElement = styled.div`
    display: flex;
    gap: 24px;
    & .icon {
        margin-top: 36px;
        flex: none;
        width: 24px;
        height: 24px;
    }
`;

const HowReceiveMoneySectionComponentDetailItemText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const HowReceiveMoneySectionComponentDetailItemAdditional = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        display: none;
        top: 0px;
        height: 100%;
        width: 100%;
    }
`;
const HowReceiveMoneySectionComponentDetailItemImage = styled.div`
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        display: none;
        height: 0;
    }
    
`;
