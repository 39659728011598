import styled from "styled-components";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {useWindowSize} from "@tools/hooks";

export const TelegramInfoSection = () => {

    const {width} = useWindowSize();

    return (
        <TelegramInfoSectionContainer>
            <TelegramInfoSectionItem>
                <TelegramInfoSectionItemImage>
                    <StaticImage
                        src="../../../images/telegram/TelegramInfoSectionImage.png"
                        loading="eager"
                        placeholder="none"
                        formats={["auto", "webp"]}
                        layout="constrained"
                        objectFit="contain"
                        alt=""
                    />
                </TelegramInfoSectionItemImage>
                <TelegramInfoSectionItemInfo>
                    <TelegramInfoSectionItemTitle swichPoint={LAPTOP_MOBILE_POINTER}>Для самозанятых сервис Кошелек автоматически сформирует чек после оплаты, отправит его заказчику и напомнит оплатить налог</TelegramInfoSectionItemTitle>
                </TelegramInfoSectionItemInfo>
            </TelegramInfoSectionItem>
        </TelegramInfoSectionContainer>
    )
};

const TelegramInfoSectionContainer = styled.section`
    margin: 160px 0px 160px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 80px 0 80px;
    }
    @media (max-width: 609px) {
        margin: 80px -19px 80px;
    }
`;

const TelegramInfoSectionItem = styled.div`
    overflow: hidden;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        padding: 20px;
    }
`;

const TelegramInfoSectionItemInfo = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
`;

const TelegramInfoSectionItemTitle = styled(TypographyUI.H3)`
    margin-bottom: 20px;
    max-width: 880px
`;

const TelegramInfoSectionItemImage = styled.div`
    width: 330px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }

`;
