import styled from "styled-components";
import React from "react";
import {TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {useWindowSize} from "@tools/hooks";
import srcImg1 from "@images/telegram/PayTelegramSectionImage1.png";
import srcImg2 from "@images/telegram/PayTelegramSectionImage2.png";

export const PayTelegramSection = () => {

    const {width} = useWindowSize();

    return (
        <PayTelegramSectionContainer>

            <PayTelegramSectionTitle>Получайте оплату в Телеграм с помощью нашего платежного сервиса</PayTelegramSectionTitle>

            <PayTelegramSectionItems>
                <PayTelegramSectionItem>
                    <PayTelegramSectionItemImage>
                        <img src={srcImg1} alt={''}/>
                    </PayTelegramSectionItemImage>
                    <PayTelegramSectionItemContent>
                        <PayTelegramSectionItemContentTitle>
                            Самозанятым
                        </PayTelegramSectionItemContentTitle>
                        <PayTelegramSectionItemContentDescription>
                            Чтобы автоматизировать прием платежей за услуги или товары
                        </PayTelegramSectionItemContentDescription>
                    </PayTelegramSectionItemContent>
                </PayTelegramSectionItem>

                <PayTelegramSectionItem>
                    <PayTelegramSectionItemImage>
                        <img src={srcImg2} alt={''}/>
                    </PayTelegramSectionItemImage>
                    <PayTelegramSectionItemContent>
                        <PayTelegramSectionItemContentTitle>
                            Физическим лицам
                        </PayTelegramSectionItemContentTitle>
                        <PayTelegramSectionItemContentDescription>
                            Чтобы получать донаты и разовые переводы от родственников и знакомых
                        </PayTelegramSectionItemContentDescription>
                    </PayTelegramSectionItemContent>
                </PayTelegramSectionItem>

            </PayTelegramSectionItems>
        </PayTelegramSectionContainer>
    )
};

const PayTelegramSectionContainer = styled.section`
    display: flex;
    margin-top: 160px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: relative;
    flex-direction: column;
    margin-bottom: 100px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 80px -19px 40px;
        height: 100%;
        padding: 30px 20px;
    }
`;

const PayTelegramSectionTitle = styled(TypographyUI.H2)`
    margin-bottom: 24px;
    max-width: 820px;
    text-align: center;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 100%;
    }
`;

const PayTelegramSectionItems  = styled.div`
    display: flex;
    gap: 47px;
    flex-wrap: wrap;
    margin-top: 80px;
    justify-content: space-between;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin-top: 40px;
    }
`;

const PayTelegramSectionItem = styled.div`
    display: flex;
    gap: 36px;
`;


const PayTelegramSectionItemImage = styled.div`
    width: 65px;
    height: 65px;
    flex: none;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 60px;
        height: 60px;
    }
    img {
        width: 65px;
        height: 65px;
        @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
            width: 60px;
            height: 60px;
        }
    }
`;

const PayTelegramSectionItemContent = styled.div`
    width: 445px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: auto;
    }
`;

const PayTelegramSectionItemContentTitle = styled(TypographyUI.H3)`
    margin-bottom: 16px;
`;

const PayTelegramSectionItemContentDescription = styled(TypographyUI.Body1Regular)`
    color: var(--text-secondary);
`;

